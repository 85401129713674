export const initValuesToClientForm = (priceId, create) => (
    {
        id: null,
        serviceValue: null,
        scopeOfWork: null,
        price: null,
        prices: [{
            id: create ? priceId : null,
            from: null,
            to: null,
            render_price: null
        }]
    }
);
