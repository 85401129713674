import {Field} from "react-final-form";
import React from "react";

export const ServicesCreateForm = ({services,setServices, index, canEditPrices}) => {
    return (
        <>
            {services.map((service, key) => (
                <div key={`service-create-${service.id}-${index}-${key}`}>
                    <div className="content__wrapper">
                        <Field
                            name={`apartment_services[${key}][id]`}
                            type="checkbox"
                            render={({input}) => {
                                return (
                                    <div className="content__wrapper">
                                        <div className="box1">
                                            <label className="check_box">{service.name}
                                                <input {...input}
                                                       checked={service.obligatory}
                                                       onChange={(e) => {
                                                           input.onChange(e);
                                                           const temp = [...services];
                                                           temp[key].obligatory = e.target.checked ? 1: 0;
                                                           setServices(temp);
                                                       }}
                                                />
                                                <span className="checkmark"/>
                                            </label>
                                        </div>
                                    </div>
                                );
                            }}
                        />

                        {canEditPrices && (
                            <Field
                                name={`apartment_services[${key}][price]`}
                                type="number"
                                render={({input}) => {
                                    return (
                                        <div className="box1">
                                            <input {...input} className="input__price" min="0" step="0.01"
                                                   value={service.price}
                                                   onChange={(e) => {
                                                       input.onChange(e);
                                                       const temp = [...services];
                                                       temp[key].price = e.target.value;
                                                       setServices(temp);
                                                   }}
                                            />
                                        </div>
                                    );
                                }}
                            />
                        )}
                    </div>

                    {service.scopeOfWork !== null && (
                        <div className="content__wrapper content__container">
                            <span style={{fontSize: 10}}>{service.scopeOfWork}</span>
                        </div>
                    )}
                </div>

            ))}
        </>
    );
}
