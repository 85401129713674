import React from "react";
import {toggleFree} from "../../../actions/task/Actions";

export const ToggleFreeButton = ({taskId, baseUrl, title, onClick, className, params}) => {
    return <>
        <button type="button"
                className={`btn btn-primary salmon ${className}`}
                onClick={() => {
                    if (taskId !== null && baseUrl !== null) {
                        toggleFree(baseUrl, taskId).finally(() => {
                            if (onClick instanceof Function) {
                                onClick();
                            }
                        });
                    }
                }}
                {...params}
        >
            {title ?? 'Wolny'}
        </button>
    </>
}
