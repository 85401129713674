import axios from "axios";

export const addGroup = (baseUrl, clientId, groupId) => {
    axios.post(`${baseUrl}/api/clients/${clientId}/group/${groupId}/add`).then(()=>{
        window.alert('Wykonano');
    }).catch((e)=>{
        console.log(e);
        window.alert('Coś poszło nie tak');
    })
};

export const addServiceToApartments = (baseUrl, clientId, serviceId) => {
    axios.post(`${baseUrl}/api/clients/${clientId}/service/${serviceId}/add`).then(()=>{
        window.alert('Wykonano');
    }).catch((e)=>{
        console.log(e);
        window.alert('Coś poszło nie tak');
    })
};

export const updatePricesInApartments = (baseUrl, clientId, priceId) => {
    axios.put(`${baseUrl}/api/clients/${clientId}/servicePrice/${priceId}/update`).then(()=>{
        window.alert('Wykonano');
    }).catch((e)=>{
        console.log(e);
        window.alert('Coś poszło nie tak');
    })
};

export const removeServiceFromApartments = (baseUrl, clientId, serviceId, closure) => {
    axios.delete(`${baseUrl}/api/clients/${clientId}/service/${serviceId}/remove`).then(()=>{
        window.alert('Wykonano');
        if (closure instanceof Function) {
            closure();
        }
    }).catch((e)=>{
        console.log(e);
        window.alert('Coś poszło nie tak');
    })
};

export const updateServiceFromApartments = (baseUrl, clientId, serviceId) => {
    axios.put(`${baseUrl}/api/clients/${clientId}/service/${serviceId}/update`).then(()=>{
        window.alert('Wykonano');
    }).catch((e)=>{
        console.log(e);
        window.alert('Coś poszło nie tak');
    })
};
