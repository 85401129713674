import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import Select from "react-select";
import axios from 'axios';

export const Services = ({url, clients, inituser, initgroups, customservices, create}) => {
    const [userId, setUserId] = useState(inituser);
    const [groups, setGroups] = useState([]);
    const [canUpdate, setCanUpdate] = useState(!!create);
    const [size, setSize] = useState(document.querySelector('#size').value);
    const [customServices, setCustomServices] = useState(customservices);
    let [count, setCount] = useState((inituser !== "") ? 0 : 1);

    const [serviceGroups, setServiceGroups] = useState(initgroups ?? []);

    const types = [
        {
            value: 'inny',
            label: 'Inny',
        },
        {
            value: 'm2',
            label: 'm2',
        }
    ]

    useEffect(() => {
        document.querySelector('#size').addEventListener('change', (e) => {
            setSize(e.target.value)
        })
    }, [])

    useEffect(() => {
        if (userId !== "" && userId !== null) {
            axios.get(`${url}/api/apartments/${userId}/groups`).then(({data}) => {
                setGroups(data.groups);
                setCount(count + 1);
            })
        }
    }, [userId])

    useEffect(() => {
        if (size) {
            if (canUpdate) {
                let tempServiceGroups = [...serviceGroups];
                tempServiceGroups.map((groupItem) => {
                    if (groupItem.type !== 'inny') {
                        for (let i = 0; i < groupItem.services.length; i++) {
                            groupItem.services[i].price = groupItem.services[i].prices.filter(item => item.from <= size && item.to >= size).shift()?.price ?? 0.0
                        }
                    }
                    return groupItem;
                })
                setServiceGroups(tempServiceGroups);
            } else {
                setCanUpdate(true);
            }
        }
    }, [size]);


    return (
        <div>
            <div className="form-col">
                <Select name="user_id" placeholder="Klient" options={clients}
                        defaultValue={clients.find((item) => inituser == item.value)} onChange={e => {
                    setUserId(e.value);
                    setServiceGroups([]);
                }}
                />
            </div>

            <div className="form-col form-col-empty"/>

            <div className="form-col service-adding service-adding-col2">
                <div className="additional__services__action">
                    <div className="add-plus" onClick={() => {
                        setServiceGroups(serviceGroups => [...serviceGroups, {
                            id: `${serviceGroups.length}-serviceGroups`,
                            client_group_id: null,
                            type: null,
                            services: []
                        }])
                    }}>
                        <i className="flaticon-plus-symbol-button"/>
                    </div>

                    <div className="add-minus" onClick={() => {
                        let newArr = [...serviceGroups];
                        newArr.pop();
                        setServiceGroups([...newArr]);
                    }}>
                        <i className="fa fa-minus"/>
                    </div>
                </div>
            </div>
            {serviceGroups.map((serviceGroupItem, serviceGroupKey) => (
                <div className="form-col service-adding service-adding-col2" key={serviceGroupItem.id}>
                    <div className="form-col">
                        <input name={`groups[${serviceGroupKey}][type]`} hidden readOnly
                               value={serviceGroupItem.type}/>
                        <Select name={`groups[${serviceGroupKey}][client_group_id]`} placeholder="Grupy" options={groups}
                                value={groups.find((item) => serviceGroupItem.client_group_id == item.value)}
                                onChange={e => {
                                    if (!serviceGroups.filter(item => item.client_group_id === e.value).length) {
                                        let newArr = [...serviceGroups];
                                        newArr[serviceGroupKey].client_group_id = e.value;
                                        newArr[serviceGroupKey].type = e.type;
                                        newArr[serviceGroupKey].addNew = true;
                                        axios.get(`${url}/api/apartments/${e.value}/services`).then(({data}) => {
                                            let tempServices = data.services;
                                            if (e.type !== 'inny') {
                                                for (let i = 0; i < data.services.length; i++) {
                                                    tempServices[i].price = data.services[i].prices.filter(item => item.from <= size && item.to >= size).shift()?.price ?? 0.0
                                                }
                                            }
                                            newArr[serviceGroupKey].services = tempServices;
                                        }).finally(() => {
                                            setServiceGroups(newArr);
                                        })
                                    }
                                }}
                        />
                    </div>
                    <div className="form-col">
                        <p>Typ mnożnika: {serviceGroupItem.type !== null && serviceGroupItem.type}</p>
                    </div>

                    {serviceGroupItem.services.map((service, index) => (
                        <div className="form-col service-adding" key={`${service.id}-${serviceGroupItem.id}`}>
                            <div className="service-adding-col clearfix">
                                <div className="service-adding-col-left">
                                    <h5>{service.name}</h5>
                                    <input name={`groups[${serviceGroupKey}][services][${index}][name]`} hidden readOnly
                                           value={service.name}/>
                                    {!!serviceGroupItem.addNew ? (
                                        <input name={`groups[${serviceGroupKey}][services][${index}][client_service_id]`} hidden readOnly value={service?.id}/>
                                    ) : (
                                        <input name={`groups[${serviceGroupKey}][services][${index}][id]`} hidden readOnly
                                               value={service.id}/>
                                    )}
                                    {serviceGroupItem.type === 'inny' && (
                                        <input name={`groups[${serviceGroupKey}][services][${index}][multiplier]`} type="number"
                                               step="0.1"
                                               value={service.multiplier} placeholder="Mnożnik"
                                               onChange={(e) => {
                                                   let newArr = [...serviceGroups];
                                                   newArr[serviceGroupKey].services[index].multiplier = e.target.value;
                                                   setServiceGroups(newArr);
                                               }}
                                        />
                                    )}
                                    {serviceGroupItem.type === 'zakres' && (
                                        <input name={`groups[${serviceGroupKey}][services][${index}][multiplier]`} type="hidden"
                                               step="0.1"
                                               value={1}
                                        />
                                    )}
                                    <input name={`groups[${serviceGroupKey}][services][${index}][price]`} type="number" step="0.001"
                                           value={service.price}
                                           onChange={(e) => {
                                               let newArr = [...serviceGroups];
                                               newArr[serviceGroupKey].services[index].price = e.target.value;
                                               setServiceGroups(newArr);
                                           }}
                                    />
                                    <div className="clear"/>
                                </div>
                                <div className="service-adding-col-right">
                                    {serviceGroupItem.type !== 'inny' ? (
                                        <h5>Suma: {size !== null && (
                                            <>
                                                <span className="sum">{((serviceGroupItem.type === 'm2' ? size : 1) * service.price).toFixed(2)}</span>EUR
                                            </>
                                        )}</h5>
                                    ) : (
                                        <h5>Suma: <span
                                            className="sum">{(service.multiplier * service.price).toFixed(2)}</span>EUR
                                        </h5>
                                    )}
                                    <div className="adding-col-sub">
                                        <input name={`groups[${serviceGroupKey}][services][${index}][scopeOfWork]`} type="text"
                                               defaultValue={service.scopeOfWork}/>
                                        <i className="fa fa-trash" aria-hidden="true"
                                           onClick={() => {
                                               let newArr = [...serviceGroups];
                                               newArr[serviceGroupKey].services.splice(index, 1);
                                               if (!newArr[serviceGroupKey].services.length) {
                                                   newArr.splice(serviceGroupKey, 1);
                                               }
                                               setServiceGroups(newArr);
                                           }}
                                        />
                                        <div className="radio_box_area">
                                            <div>
                                                <input name={`groups[${serviceGroupKey}][services][${index}][obligatory]`}
                                                       type="checkbox"
                                                       id={`obligatory-${serviceGroupKey}-${index}]`}
                                                       checked={service.obligatory}
                                                       value={1}
                                                       onChange={(e) => {
                                                           let newArr = [...serviceGroups];
                                                           newArr[serviceGroupKey].services[index].obligatory = e.target.checked;
                                                           setServiceGroups(newArr);
                                                       }}
                                                />
                                                <label htmlFor={`obligatory-${serviceGroupKey}-${index}]`}/>
                                            </div>
                                        </div>
                                        <div className="clear"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}

            <div className="form-col service-adding service-adding-col2">
                <div className="additional__services__action">
                    <p>Własne dodatkowe usługi</p>
                    <div className="add-plus" onClick={() => {
                        let newServices = [...customServices];
                        newServices.push({
                            id: `${newServices.length}-customServices`,
                            name: "",
                            multiplier: null,
                            price: null,
                            scopeOfWork: "",
                            obligatory: false,
                            type: 'inny',
                            newAdd:true
                        });
                        setCustomServices(newServices);
                    }}>
                        <i className="flaticon-plus-symbol-button"/>
                    </div>
                </div>

                {customServices.map((service, index) => (
                    <div key={service.id} className="additional__services__wrapper">
                        <div className="service-adding-col clearfix">
                            <div className="service-adding-col-left">
                                <input name={`customServices[${index}][name]`} className="service-name" type="text"
                                       placeholder="Nazwa usługi" defaultValue={service.name}
                                       onChange={(e) => {
                                           let newServices = [...customServices];
                                           newServices[index].name = e.target.value;
                                           setCustomServices(newServices);
                                       }}
                                />
                                {!!!service.newAdd && (
                                    <input name={`customServices[${index}][id]`} hidden readOnly value={service.id}/>
                                )}
                                {service.type !== 'm2' && (
                                    <input name={`customServices[${index}][multiplier]`} type="number" step="0.1"
                                           value={service.multiplier} placeholder="Mnożnik" onChange={(e) => {
                                        let newServices = [...customServices];
                                        newServices[index].multiplier = e.target.value;
                                        setCustomServices(newServices);
                                    }}
                                    />
                                )}

                                <select name={`customServices[${index}][type]`} placeholder="Typ"
                                        value={service.type}
                                        onChange={(e) => {
                                            let newServices = [...customServices];
                                            newServices[index].type = e.target.value;
                                            newServices[index].multiplier = 1;
                                            setCustomServices(newServices);
                                        }}
                                >
                                    {types.map((item, index) => (
                                        <option value={item.value}
                                                key={`${service.type}-${index}`}>{item.label}</option>
                                    ))}
                                </select>
                                <input name={`customServices[${index}][price]`} type="number" step="0.001"
                                       value={service.price} placeholder="Cena jed." onChange={(e) => {
                                    let newServices = [...customServices];
                                    newServices[index].price = e.target.value;
                                    setCustomServices(newServices);
                                }}
                                />
                                <div className="clear"/>
                            </div>
                            <div className="service-adding-col-right">
                                {service.type === 'm2' ? (
                                    <h5>Suma: {size !== null && (
                                        <>
                                            <span className="sum">{(size * service.price).toFixed(2)}</span> EUR
                                        </>
                                    )}</h5>
                                ) : (
                                    <h5>Suma: <span
                                        className="sum">{(service.multiplier * service.price).toFixed(2)}</span> EUR</h5>
                                )}
                                <div className="adding-col-sub">
                                    <input name={`customServices[${index}][scopeOfWork]`} type="text"
                                           placeholder="Zakres usługi" defaultValue={service.scopeOfWork}/>
                                    <i className="fa fa-trash" aria-hidden="true"
                                       onClick={() => {
                                           let newServices = [...customServices];
                                           newServices.splice(index, 1);
                                           setCustomServices(newServices);
                                       }}
                                    />
                                    <div className="radio_box_area">
                                        <div>
                                            <input name={`customServices[${index}][obligatory]`} type="checkbox"
                                                   id={`custom-obligatory${index}`}
                                                   checked={service.obligatory}
                                                   value={1}
                                                   onChange={(e) => {
                                                       let newServices = [...customServices];
                                                       newServices[index].obligatory = e.target.checked;
                                                       setCustomServices(newServices);
                                                   }}
                                            />
                                            <label htmlFor={`custom-obligatory${index}`}/>
                                        </div>
                                    </div>
                                    <div className="clear"/>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

if (document.getElementById('apartmentsServices')) {
    const item = document.getElementById('apartmentsServices');
    const props = Object.assign({}, item.dataset);
    props.clients = JSON.parse(props.clients);
    props.initgroups = JSON.parse(props.initgroups);
    props.customservices = JSON.parse(props.customservices);
    ReactDOM.render(<Services {...props} />, item);
}
