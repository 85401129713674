/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./components/Group/UsersForm');
require('./components/Clients/ClientsForm');
require('./components/Apartments/Map');
require('./components/Apartments/Services');
require('./components/Apartments/FileInput');
require('./components/Apartments/Images');
require('./components/SmartSelect');
require('./components/SmartEditor');
require('./components/GroupTask');
require('./components/ImageGalleryView');
require('./components/Comments/Comments');
require('./components/Reports/Report');
require('./components/Colors');
require('./components/GroupOrder/GroupOrder');
/*require('./components/Tasks/Create');
require('./components/Tasks/List');*/
require('./pages/dashboard');
require('./pages/calendar');
