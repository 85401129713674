import React from 'react';
import {Field, Form} from "react-final-form";

export const TaskComments = ({comments, onSubmit,messageFile, setMessageFile}) => {
    return (
        <Form
            initialValues={{msg:""}}
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row--bottom--margin">
                        <div className="col-sm-12">
                            <Field
                                name="msg"
                                type="text"
                                render={({input, meta}) => (
                                    <div className="form-col msgg">
                                        <textarea {...input} placeholder="Wiadomość"/>
                                        {meta.error && meta.touched && <span className="error--msg">{meta.error}</span>}
                                    </div>
                                )}
                            />
                        </div>
                        <div className="col-sm-6">


                            <Field
                                name='attachment'
                                type="file"
                                render={({input, meta}) => (
                                    <div className="task-file-uploaded clearfix">
                                        {messageFile ? messageFile.name : "Wybierz plik"}
                                        <input type="file" className="attachment"
                                               onChange={(e) => {
                                                   input.onChange(e);
                                                   setMessageFile(e.target.files[0]);
                                               }}
                                        />
                                    </div>
                                )}/>
                        </div>
                        <div className="col-sm-6">
                            <button type="submit" className="task-file-uploaded clearfix black">
                                Wyślij wiad/plik
                            </button>
                        </div>
                    </div>
                    <div className="comment-listings">
                        {comments.map((comment,index) => (
                            <div className="comment-col" key={index}>
                                <div className="comment-date-info">{comment.created_at} {comment.userName}</div>
                                <p>{comment.msg}</p>
                                <img src={comment.image} alt=""/>
                            </div>
                        ))}
                    </div>
                </form>
            )}
        />
    );
}
