import React, {useState} from 'react';
import ReactDOM from "react-dom";

export const FileInput = ({}) => {
    const [files, setFiles] = useState([]);

    return (
        <>
            <label className="attachmentBtn">
                <input name="images[]"
                       multiple
                       type="file"
                       onChange={(e)=>{
                            const selectedFiles = e.target.files;
                            if (selectedFiles.length) {
                                const names = [];
                                for (const [key, value] of Object.entries(selectedFiles)) {
                                    names.push({name:value.name, object: URL.createObjectURL(selectedFiles[key])});
                                }
                                setFiles(names);
                            } else {
                                setFiles([]);
                            }
                            }}
                />
                Wybierz zdjęcia
            </label>

            {files.length > 0 && (
                <div>
                    {files.map((file) => (
                        <div style={{display:'flex', flexDirection:'column', marginBottom:'10px'}}>
                            <span style={{marginBottom:'5px'}}>{file.name}</span>
                            <img src={file.object} style={{width:'80px', height:"auto"}} onError={(e) => {
                                event.target.style.display = 'none'
                            }}/>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

if (document.getElementById('fileInput')) {
    const item = document.getElementById('fileInput');
    const props = Object.assign({}, item.dataset);
    ReactDOM.render(<FileInput {...props} />, item);
}
