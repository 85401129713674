import React, {useEffect, useState} from "react";
import moment from "moment";
import {MonthlyNav} from "./MonthlyNav";
import {chunks} from "../../helpers/generators";
import {MonthlyRow} from "./MonthlyRow";
import {getDates} from "../../helpers/dates";

export const MonthlyCalendar = ({currentMonth, onCurrentMonthChange, days, onClick, tasks = []}) => {
    const [currentDate, setCurrentDate] = useState(moment(currentMonth).locale('pl'));

    useEffect(() => {
        setCurrentDate(moment(currentMonth).locale('pl'))
    }, [currentMonth]);


    const getCalendarDays = () => {
        let startMonth = moment(currentDate).locale('pl').month();
        let startDate = moment(currentDate).locale('pl').startOf('week');
        let endDate = moment(currentDate).locale('pl').add(1,'M').startOf('month');

        return getDates(startDate, endDate).map((date) => (
            {
                disabled: !(startMonth === date.month()),
                day: date,
                tasks: tasks.filter(item => {
                    return moment(item.date_of_order).format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY')
                })
            }
        ));
    }

    return (
        <div className="calenderContainer">
            <div className="calendar">
                <MonthlyNav currentMonth={currentMonth} onCurrentMonthChange={onCurrentMonthChange}/>
                <div className="days row">
                    {days.map((day, index) => <div className="col col-center" key={`days-${index}`}>{day}</div>)}
                </div>
                <div className="body">
                    {[...chunks(getCalendarDays(), 7)].map((item, index) => (
                        <MonthlyRow key={`month-${index}`} days={item} onClick={onClick}/>
                    ))}
                </div>
            </div>
        </div>
    );
}
