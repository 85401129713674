import React, {useEffect, useState} from 'react';
import {getColors} from "../actions/colors/Actions";
import ReactDOM from "react-dom";

const Colors = ({url}) => {
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        getColors(url).then((response)=>{
            setStatuses(response.data.statuses);
        })
    }, []);

    return (
        <div className="colors__wrapper">
            {statuses.map(item => (
                <div className="colors__item">
                    <span className="colors__name">{item.name}:</span>
                    <div className={`listings-info-column ${item.color} colors__example`}/>
                </div>
            ))}
        </div>
    )
}

document.querySelectorAll('.colorStatuses').forEach((item) => {
    const props = Object.assign({}, item.dataset);
    ReactDOM.render(<Colors {...props} />, item);
})
