import axios from "axios";
import {TaskComments} from "./TaskComments";
import React, {useState} from "react";
import ReactDOM from "react-dom";

const Comments = ({url, comments}) => {
    const [messageFile, setMessageFile] = useState(null);
    const [coms, setComs] = useState(comments);

    return (
        <>
            <TaskComments
                comments={coms}
                messageFile={messageFile}
                setMessageFile={setMessageFile}
                onSubmit={(values, form) => {
                    const formData = new FormData();
                    Object.keys(values).forEach((key) => {
                        formData.append(key, values[key] ?? '')
                    });
                    if (messageFile) {
                        formData.append('image', messageFile);
                    }
                    axios.post(`${url}`, formData).then(({data}) => {
                        form.reset();
                        setMessageFile(null);
                        setComs(data.comments);
                    }).catch((e) => {
                        console.log(e);
                    })
                }}
            />
        </>
    );
}

if (document.getElementById('commentsComponent')) {
    const item = document.getElementById('commentsComponent');
    const props = Object.assign({}, item.dataset);
    props.comments = JSON.parse(props.comments);
    ReactDOM.render(<Comments {...props} />, item);
}
