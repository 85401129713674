import React from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select'

export const SmartSelect = ({name, placeholder, options =[], value = null, label = null}) => {
    let defaultValue = null;
    if (value && label) {
        defaultValue={value:value, label: label};
    }
    return (
        <Select name={name} placeholder={placeholder} options={options} defaultValue={defaultValue}/>
    )
};

export default SmartSelect;

document.querySelectorAll('.SmartSelectComponent').forEach((item)=>{
    const props = Object.assign({}, item.dataset);
    props.options = JSON.parse(props.options);
    ReactDOM.render(<SmartSelect {...props} />, item);
})
