import React from "react";

export const ActionButton = ({show, closure = {}, params = {}, iconClass = "fas fa-pen-alt", wrapperClasses = ""}) => {
    return (
        <div className={`form-col services__action__wrapper`} {...params}>
            {show && (
                <div className={`services__action_update add-plus ${wrapperClasses}`} onClick={() => {
                    if (closure?.msg && closure?.action) {
                        if (window.confirm(closure?.msg)) {
                            closure?.action(...closure?.params)
                        }
                    }
                    }}>
                    <i className={iconClass}/>
                </div>
            )}
        </div>
    )
}
