import React, {useEffect, useState} from "react";
import Select from "react-select";

export const ReportApartmentsForm = ({initReportApartments, apartments = []}) => {

    const [reportApartments, setReportApartments] = useState([]);
    const [selectOptions, setSelectOptions] = useState([]);

    const days = [];

    useEffect(() => {
        setReportApartments(initReportApartments);
        setSelectOptions(apartments.map((apartment) => ({
            value: apartment.id,
            label: apartment.name
        })));
    }, [initReportApartments, apartments])

    return (
        <>
            {reportApartments.map((reportApartment, key) => {
                let displayDate = false;
                if (!days.find(item => item === reportApartment.done_at)) {
                    days.push(reportApartment.done_at);
                    displayDate = true;
                }
                return (
                    <div key={`${reportApartment.id}-${key}`}>
                        <div className="report_apartment_wrapper">
                            <h2>{displayDate && (
                                <>Z dnia: {reportApartment.done_at}</>
                            )}</h2>
                            <div>
                                <div className="add-minus" onClick={() => {
                                    let newArr = [...reportApartments];
                                    newArr.splice(key, 1);
                                    setReportApartments([...newArr]);
                                }}>
                                    <i className="fa fa-minus"/>
                                </div>
                            </div>
                            {reportApartment.id !== null && (
                                <input type="hidden" name={`reportApartments[${key}][id]`} value={reportApartment.id}/>
                            )}
                            <div className="form-col">
                                <label htmlFor="">Nazwa Apartamentu</label>
                                {reportApartment.apartment_id !== null && (
                                    <input type="hidden" name={`reportApartments[${key}][apartment_id]`} value={reportApartment.apartment_id}/>
                                )}
                                <Select placeholder="Nazwa Apartamentu"
                                        name={`reportApartments[${key}][apartment_id]`}
                                        options={selectOptions}
                                        value={selectOptions.find((item) => reportApartment?.apartment_id == item.value)}
                                        isDisabled={true}
                                        onChange={e => {
                                            input.onChange(e);
                                            let newArr = [...reportApartments];
                                            newArr[key].apartment_id = e.target.value;
                                            setReportApartments(newArr);
                                        }}
                                />

                                <div className="tasks_info">
                                    <span>Grupa: {reportApartment?.group?.name ?? '-'}</span>
                                    <span>Metraż: {reportApartment.size} m2</span>
                                    <span>
                                        Suma: {reportApartment.report_services.reduce((accumulator,reportService ) => accumulator + parseFloat((reportService?.render_price ?? 0)),0)}
                                    </span>
                                </div>
                            </div>
                            <div className="tasks_wrapper">
                                <div>
                                    {reportApartment.report_services.map((reportService, serviceKey) => (
                                        <div className="report_services_wrapper"
                                             key={`${reportService.id}-${serviceKey}`}>
                                            <input type="hidden"
                                                   name={`reportApartments[${key}][services][${serviceKey}][id]`}
                                                   value={reportService.id}/>
                                            <div className="form-col">
                                                {serviceKey === 0 && (
                                                    <label htmlFor="">Nazwa zadania</label>
                                                )}
                                                <input required type="text"
                                                       name={`reportApartments[${key}][services][${serviceKey}][name]`}
                                                       onChange={(e) => {
                                                           let newArr = [...reportApartments];
                                                           newArr[key].report_services[serviceKey].name = e.target.value;
                                                           setReportApartments(newArr);
                                                       }}
                                                       value={reportService.name}/>
                                            </div>

                                            <div className="form-col">
                                                {serviceKey === 0 && (
                                                    <label htmlFor="">Cena</label>
                                                )}
                                                <input required type="number"
                                                       name={`reportApartments[${key}][services][${serviceKey}][price]`}
                                                       onChange={(e) => {
                                                           let newArr = [...reportApartments];
                                                           newArr[key].report_services[serviceKey].render_price = e.target.value;
                                                           setReportApartments(newArr);
                                                       }}
                                                       value={reportService.render_price}/>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="additional__services__action">
                                    <div className="add-plus" onClick={() => {
                                        let newArr = [...reportApartments];
                                        newArr[key].report_services.push({
                                            id: null,
                                            name: null,
                                            price: null
                                        });
                                        setReportApartments([...newArr]);
                                    }}>
                                        <i className="flaticon-plus-symbol-button"/>
                                    </div>

                                    <div className="add-minus" onClick={() => {
                                        let newArr = [...reportApartments];
                                        newArr[key].report_services.pop();
                                        setReportApartments([...newArr]);
                                    }}>
                                        <i className="fa fa-minus"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    );
}
