import React, {useEffect, useState} from 'react';
import {TaskForm} from "./Form";
import axios from "axios";

export const CreateTask = ({statuses, groups, groupId, apartments, url, lat, lng, updateTaskBoard, date, isAdmin}) => {
    const [group, setGroup] = useState(groupId);

    const init = {
        task_status_id: {
            value: statuses[0]?.id
        },
        group_id: group ?? null,
        apartment_id: null,
        address: null,
        date_of_order: date,
        end_at: date,
        guest_arrival_at: date,
        people: null,
        manager: null,
        car: null,
        client_description: null,
        apartment_services: [],
        addNext: false,
        cyclical: []
    };

    useEffect(() => {
        setGroup(groupId);
    }, [groupId]);


    const closeModal = () => {
        document.querySelector('.notification.notification-pop-btn').click();
    }

    return (
        <TaskForm
            statuses={statuses}
            groups={groups}
            apartments={apartments}
            url={url}
            lat={lat}
            lng={lng}
            createNewTask={true}
            cyclical={true}
            initialValues={init}
            statusInit={statuses[0]?.id ?? null}
            isAdmin={isAdmin}
            groupInit={group}
            onSubmit={(values, form, resetForm, services = [], serviceGroups = [], cyclicalDates = []) => {
                let copyValues = Object.assign({}, values);
                let addNext = values.addNext;
                copyValues.task_status_id = copyValues.task_status_id?.value;
                copyValues.apartment_id = copyValues.apartment_id?.value;
                copyValues.group_id = copyValues.group_id?.value ?? copyValues.group_id;

                if (services.length) {
                    if (isAdmin == 1) {
                        copyValues.apartment_services = services.filter(item => item.obligatory);
                    } else {
                        copyValues.apartment_services = services.filter(item => item.obligatory).map(item => ({
                            id: item.id
                        }));
                    }
                }

                if(serviceGroups.length){
                    copyValues.groups = serviceGroups.map(item => (
                        {
                            services: item.services.filter(item => item.obligatory)
                        }
                    ));
                    if (isAdmin != 1) {
                        copyValues.groups = copyValues.groups.map(item => (
                            {
                                services: item.services.map(item => ({id: item.id}))
                            }
                        ))
                    }
                }

                if (copyValues.cyclical.length && cyclicalDates.length) {
                    copyValues.cyclical = 1;
                    copyValues.cyclical_dates = cyclicalDates.map(item=> {
                        return item.format('YYYY-MM-DD')
                    });
                } else {
                    copyValues.cyclical = 0;
                    copyValues.cyclical_dates = [];
                }

                copyValues.periodicity = copyValues?.periodicity?.value ?? null;

                axios.post(`${url}/tasks`, copyValues).then(() => {
                    if (addNext) {
                        resetForm(true);
                        setGroup(copyValues.group_id);
                    } else {
                        form.reset(init);
                        closeModal();
                        resetForm(false);
                    }
                    updateTaskBoard();
                }).catch((e) => {
                    if(e?.response?.status === 423){
                        alert('Apartament do tego dnia został już dodany');
                    }
                })
            }}
        />
    );
}
