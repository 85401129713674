import Select from "react-select";
import {MapContainer, Marker, TileLayer, useMap} from "react-leaflet";
import {Form, Field} from "react-final-form";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {required} from "../../helpers/validators";
import arrayMutators from "final-form-arrays";
import {ServicesEdit} from "./ServicesEdit";
import {ServicesCreate} from "./ServicesCreate";
import {ClientServicesCreate} from "./ClientServicesCreate";
import {CreateCustomServices} from "./CreateCustomServices";
import DatePicker from "react-multi-date-picker";
import {ToggleFreeButton} from "./ActionButtons/ToggleFreeButton";


export const TaskForm = ({
                             statuses,
                             groups,
                             apartments,
                             url,
                             lat,
                             lng,
                             initialValues,
                             onSubmit,
                             apartmentInit = null,
                             groupInit = null,
                             statusInit = null,
                             taskServicesInit = [],
                             createNewTask = false,
                             cyclical = false,
                             isAdmin,
                             closeModal
                         }) => {
    const [apartment, setApartment] = useState(null);
    const [group, setGroup] = useState(null);
    const [status, setStatus] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [services, setServices] = useState([]);
    const [serviceGroups, setServiceGroups] = useState([]);
    const [taskServices, setTaskServices] = useState([]);
    const [center, setCenter] = useState({lat, lng});
    const [resetForm, setResetForm] = useState(1);
    const [dates, setDates] = useState([]);

    useEffect(() => {
        if (apartment !== null) {
            axios.get(`${url}/api/apartments/${apartment.id}/firstImage`).then(({data}) => {
                setImageUrl(data.url);
            })
            axios.get(`${url}/api/apartments/services/${apartment.id}`).then(({data}) => {
                if (!createNewTask) {
                    setServices(
                        [...data.services].map((item) => {
                            item.obligatory = false;
                            return item;
                        })
                    )
                }
                setServices([...data.services]);
            })
            const lat = apartment.lat;
            const lng = apartment.lng;
            setCenter({lat, lng});
            setResetForm(resetForm + 1);
        }
    }, [apartment]);

    useEffect(() => {
        if (apartmentInit !== null) {
            setApartment(apartments.find((apartment) => apartment.id === apartmentInit))
        }
    }, [apartmentInit])

    useEffect(() => {
        if (groupInit !== null) {
            setGroup(groups.find((group) => group.id === groupInit))
        }
    }, [groupInit])

    useEffect(() => {
        if (statusInit !== null) {
            setStatus(statuses.find((status) => status.id === statusInit))
        }
    }, [statusInit])

    useEffect(() => {
        if (taskServicesInit !== null) {
            setTaskServices(taskServicesInit)
        }
    }, [statusInit])

    useEffect(() => {
        if (createNewTask) {
            setTaskServices(
                services.filter(service => service.obligatory == true).map(service => (
                    {
                        apartment_service_id: service.id
                    }
                ))
            )
        }
    }, [services])

    useEffect(() => {
        window.setFormValue('apartment_services', taskServices.map(item => item.apartment_service_id))
    }, [taskServices]);

    const ChangeView = ({center, zoom}) => {
        const map = useMap();
        map.setView(center, zoom);
        map.invalidateSize();
        return null;
    }

    const ResetForm = (onlyApartment) => {
        if (onlyApartment) {
            setApartment(null);
        } else {
            setApartment(null);
            setGroup(null);
        }
    }

    return (
        <Form
            initialValues={initialValues}
            onSubmit={(values, form) => {
                onSubmit(values, form, ResetForm, services, serviceGroups, dates)
            }}
            mutators={{
                setValue: ([field, value], state, {changeValue}) => {
                    changeValue(state, field, () => value)
                },
                ...arrayMutators
            }}
            render={({handleSubmit, form, values}) => {
                if (!window.setFormValue) window.setFormValue = form.mutators.setValue
                return (
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-12 taskboard__save--top">
                                <button type="submit"
                                        className="btn btn-primary blue"
                                        onClick={() => {
                                            form.change("addNext", false);
                                        }}
                                >
                                    Zapisz
                                </button>
                                {initialValues.id !== undefined && initialValues.id !== null && (
                                    <ToggleFreeButton
                                        baseUrl={url}
                                        taskId={initialValues.id}
                                        onClick={() => {
                                            closeModal()
                                        }}
                                    />
                                )}
                            </div>
                            <div className="col-sm-12">
                                <Field
                                    name='task_status_id'
                                    validate={required}
                                    render={({input, meta}) => (
                                        <div className="form-col smart--select__first">
                                            <Select {...input} placeholder="Status"
                                                    options={statuses.map((status) => ({
                                                        value: status.id,
                                                        label: status.name
                                                    }))}
                                                    value={statuses.filter((item) => item.id === status?.id).map((status) => ({
                                                        value: status.id,
                                                        label: status.name
                                                    }))}
                                                    onChange={e => {
                                                        input.onChange(e);
                                                        let status = statuses.find((statuses) => statuses.id === e.value);
                                                        setStatus(status);
                                                    }}
                                            />
                                            {meta.error && meta.touched &&
                                            <span className="error--msg">{meta.error}</span>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="col-sm-12">
                                <Field
                                    name='group_id'
                                    render={({input, meta}) => (
                                        <div className="form-col smart--select__second">
                                            <Select {...input} placeholder="Grupa"
                                                    options={groups.map((group) => ({
                                                        value: group.id,
                                                        label: group.name
                                                    }))}
                                                    value={groups.filter((item) => item.id === group?.id).map((group) => ({
                                                        value: group.id,
                                                        label: group.name
                                                    }))}
                                                    onChange={e => {
                                                        input.onChange(e);
                                                        let group = groups.find((group) => group.id === e.value);
                                                        setGroup(group);
                                                        form.mutators.setValue('manager', group.group_manager.name);
                                                        form.mutators.setValue('car', group.car);
                                                    }}
                                            />
                                            {meta.error && meta.touched &&
                                            <span className="error--msg">{meta.error}</span>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="col-sm-8">
                                <Field
                                    name='apartment_id'
                                    validate={required}
                                    render={({input, meta}) => (
                                        <div className="form-col smart--select__third">
                                            <Select {...input}
                                                    placeholder="Apartament"
                                                    options={apartments.map((apartment) => ({
                                                        value: apartment.id,
                                                        label: apartment.name
                                                    }))}
                                                    value={apartments.filter((item) => item.id === apartment?.id).map((apartment) => ({
                                                        value: apartment.id,
                                                        label: apartment.name
                                                    }))}
                                                    onChange={e => {
                                                        input.onChange(e);
                                                        let apartment = apartments.find((apartment) => apartment.id === e.value);
                                                        setApartment(apartment)
                                                        form.mutators.setValue('address', `${apartment.address}, ${apartment.city}`);
                                                        form.mutators.setValue('people', apartment.people);
                                                        form.mutators.setValue('client_description', apartment?.note ?? '');
                                                    }}
                                            />
                                            {meta.error && meta.touched &&
                                            <span className="error--msg">{meta.error}</span>}
                                        </div>
                                    )}
                                />
                            </div>

                            {imageUrl !== null && (
                                <div className="col-sm-4">
                                    <div className="form-col">
                                        <div className="task-images">
                                            <img src={imageUrl} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="col-sm-8">
                                <Field
                                    name="address"
                                    type="text"
                                    validate={required}
                                    render={({input, meta}) => (
                                        <div className="form-col">
                                            <input {...input} placeholder="Adres apartamentu"/>
                                            {meta.error && meta.touched &&
                                            <span className="error--msg">{meta.error}</span>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="col-sm-6">
                                <div className="form-col">
                                    <div className="task-map">
                                        <MapContainer center={center} zoom={20} style={{height: "100%", width: "100%"}}>
                                            <ChangeView center={center} zoom={20}/>
                                            <TileLayer
                                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            />
                                            <Marker position={center}/>
                                        </MapContainer>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                {lat !== null && lng !== null && (
                                    <a href={`https://maps.google.com/?q=${lat},${lng}`} target="_blank"
                                       className="btn btn-primary">
                                        Prowadź do
                                    </a>
                                )}
                            </div>

                            <div className="col-sm-6">
                                <Field
                                    name="date_of_order"
                                    type="date"
                                    validate={required}
                                    render={({input, meta}) => (
                                        <div className="form-col">
                                            <label htmlFor="" className="label__small">Data zamówienia</label>
                                            <div className="logi-date">
                                                <input {...input} placeholder="Data zamówienia" className="textbox-n"/>
                                            </div>
                                            {meta.error && meta.touched &&
                                            <span className="error--msg">{meta.error}</span>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="col-sm-6">
                                <Field
                                    name="end_at"
                                    type="date"
                                    validate={required}
                                    render={({input, meta}) => (
                                        <div className="form-col">
                                            <label htmlFor="" className="label__small">Data wykonania</label>
                                            <div className="logi-date">
                                                <input {...input} placeholder="Data wykonania" className="textbox-n"/>
                                            </div>
                                            {meta.error && meta.touched &&
                                            <span className="error--msg">{meta.error}</span>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="col-sm-12">
                                <Field
                                    name="guest_arrival_at"
                                    type="date"
                                    validate={required}
                                    render={({input, meta}) => (
                                        <div className="form-col">
                                            <label htmlFor="" className="label__small">Data przyjazdu gości</label>
                                            <div className="logi-date">
                                                <input {...input} placeholder="Data przyjazdu gości"
                                                       className="textbox-n"/>
                                            </div>
                                            {meta.error && meta.touched &&
                                            <span className="error--msg">{meta.error}</span>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="col-sm-12">
                                <Field
                                    name="people"
                                    type="text"
                                    render={({input, meta}) => (
                                        <div className="form-col">
                                            <input {...input} placeholder="Ilość osób"/>
                                            {meta.error && meta.touched &&
                                            <span className="error--msg">{meta.error}</span>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="col-sm-12">
                                <div className="form-col">
                                    <Field
                                        name="manager"
                                        type="text"
                                        render={({input, meta}) => (
                                            <div className="form-col">
                                                <input {...input} placeholder="Lider"/>
                                                {meta.error && meta.touched &&
                                                <span className="error--msg">{meta.error}</span>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-col">
                                    <Field
                                        name="car"
                                        type="text"
                                        render={({input, meta}) => (
                                            <div className="form-col">
                                                <input {...input} placeholder="Samochód"/>
                                                {meta.error && meta.touched &&
                                                <span className="error--msg">{meta.error}</span>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <Field
                                    name="client_description"
                                    type="text"
                                    render={({input, meta}) => (
                                        <div className="form-col">
                                            <textarea {...input} placeholder="Notatka"/>
                                            {meta.error && meta.touched &&
                                            <span className="error--msg">{meta.error}</span>}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="task-service">
                            {createNewTask ? (
                                <>
                                    {services.length > 0 ? (
                                        <ServicesCreate
                                            key={resetForm}
                                            index={resetForm}
                                            services={services}
                                            setServices={setServices}
                                            canEditPrices={isAdmin == 1}
                                        />
                                    ) : (
                                        <ClientServicesCreate
                                            key={resetForm}
                                            index={resetForm}
                                            baseUrl={url}
                                            apartmentId={apartment?.id}
                                            canEditPrices={isAdmin == 1}
                                            setServiceGroups={setServiceGroups}
                                        />
                                    )}
                                </>
                            ) : (
                                <ServicesEdit
                                    services={services}
                                    setServices={setServices}
                                    setServiceGroups={setServiceGroups}
                                    taskServices={taskServices}
                                    apartmentId={apartment?.id}
                                    baseUrl={url}
                                    canEditPrices={isAdmin == 1}
                                    index={resetForm}
                                />
                            )}
                            <CreateCustomServices
                                canEditPrices={isAdmin == 1}
                            />
                            {cyclical && (
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-col">
                                            <Field
                                                name="cyclical"
                                                type="checkbox"
                                                value="1"
                                                render={({input}) => (
                                                    <div className="box1">
                                                        <label className="check_box">Oznacz jako cykliczne
                                                            <input {...input}/>
                                                            <span className="checkmark"/>
                                                        </label>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {values.cyclical.length > 0 && (
                                            <div className="form-col">
                                                <Field
                                                    name="dates"
                                                    type="date"
                                                    render={({input, meta}) => (
                                                        <div className="form-col">
                                                            <label htmlFor="" className="label__small">Daty</label>
                                                            <DatePicker {...input} value={dates} onChange={setDates}/>
                                                            {meta.error && meta.touched &&
                                                            <span className="error--msg">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-sm-8">
                                    {apartmentInit === null && (
                                        <button type="submit"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    form.change("addNext", true);
                                                }}
                                        >
                                            Zapisz i dodaj kolejny <span>w tym samym dniu</span>
                                        </button>
                                    )}
                                </div>
                                <div className="col-sm-4">
                                    <button type="submit"
                                            className="btn btn-primary blue"
                                            onClick={() => {
                                                form.change("addNext", false);
                                            }}
                                    >
                                        Zapisz
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                );
            }}
        />
    );
}
