import moment from "moment";

export function getDates(start, end)
{
    let dateArray = [];
    let currentDate = moment(start);
    let stopDate = moment(end);
    while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate))
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
}
