import React, {useState} from 'react';
import ReactDOM from "react-dom";
import axios from "axios";

export const Images = ({apartmentid,initimages, url}) => {
    const [images, setImages] = useState(initimages);

    const deleteImage = (id) => {
        axios.delete(`${url}/api/apartments/${apartmentid}/image/${id}`).then(({data}) => {
            setImages(data.images)
        }).catch((e)=>{
            console.log(e);
        })
    }

    return (
        <div className="images__container">
            {images.map((image, index) => (
                <div className="image__wrapper" key={index}>
                    <button type="button" className="close" onClick={()=>{
                        deleteImage(image.id)
                        }}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                    <button type="button" className="loop" onClick={()=>{
                        let win = window.open(image.url, '_blank');
                        win.focus();
                        }}>
                        <img src={`${url}/assets/img/search-black.png`} alt=""
                        />
                    </button>
                    <img src={image.url} alt=""/>
                </div>
            ))}
        </div>
    );
};

if (document.getElementById('apartmentsImages')) {
    const item = document.getElementById('apartmentsImages');
    const props = Object.assign({}, item.dataset);
    props.initimages = JSON.parse(props.initimages);
    ReactDOM.render(<Images {...props} />, item);
}
