import React, {useState} from "react";
import {Field} from "react-final-form";
import {required} from "../../helpers/validators";

export const CreateCustomServices = ({canEditPrices}) => {
    const [services, setServices] = useState([]);

    return (
        <div className="custom__services__wrapper">
            <div className="custom__services">
                Własne usługi do zadania

                <div className="custom__services__buttons">
                    <div className="add-plus" onClick={() => {
                        let newServices = [...services];
                        newServices.push({
                            name: "",
                            price: null,
                        });
                        setServices(newServices);
                    }}>
                        <i className="flaticon-plus-symbol-button"/>
                    </div>
                    <div className="add-plus" onClick={() => {
                        setServices(services => {
                            services.pop();
                            return [...services]
                        });
                    }}>
                        <i className="fa fa-minus"/>
                    </div>
                </div>
            </div>
            <div className="custom_services__inputs">
                {services.map((service, key) => (
                    <div className="content__wrapper" key={key}>
                        <Field
                            validate={required}
                            name={`custom_services[${key}][name]`}
                            type="text"
                            render={({input,meta}) => (
                                <div className="form-col">
                                    <label htmlFor="">Nazwa</label>
                                    <input {...input} value={service.name}
                                           className="input__price"
                                           onChange={(e) => {
                                               input.onChange(e);
                                               service.name = e.target.value;
                                           }}
                                    />
                                    {meta.error && meta.touched &&
                                    <span className="error--msg">{meta.error}</span>}
                                </div>
                            )}
                        />
                        <Field
                            validate={required}
                            name={`custom_services[${key}][price]`}
                            type="number"
                            render={({input,meta}) => (
                                <div className="form-col">
                                    <label htmlFor="">Cena</label>
                                    <input {...input} value={service.price}
                                           className="input__price"
                                           onChange={(e) => {
                                               input.onChange(e);
                                               service.price = e.target.value;
                                           }}
                                           min="0"
                                           step="0.01"
                                    />
                                    {meta.error && meta.touched &&
                                    <span className="error--msg">{meta.error}</span>}
                                </div>
                            )}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
