import ReactDOM from "react-dom";
import React from "react";

const GroupTask = ({task, route}) => {

    return (
        <>
            <div className={`Apartament-info-col clearfix task-color-${task?.color} ${task?.free ? 'salmon--border' : ''}`}>
                <div className="Apartament-info">
                    <h6>{task?.clientName}</h6>
                    <h6>{task?.name} <span>{task?.address}</span></h6>
                </div>
                <div className="Apartament-info-right">
                    <a href={route} className="btn btn-primary blue">
                        Zobacz task
                    </a>
                    <a href={`https://maps.google.com/?q=${task.lat},${task.lng}`} target="_blank"
                       className="btn btn-primary">
                        <i className="fas fa-map-marker"/>
                    </a>
                    {task.clientComment !== null && (
                        <a href="#" className="btn btn-primary" data-toggle="modal" data-target={`#modal${task.id}`}><
                            i className="fa fa-info" aria-hidden="true"/>
                        </a>
                    )}
                    <span className="user">
                        {task?.people} <i className="fa fa-user" aria-hidden="true"/>
                    </span>
                    {task.arriveToDay && (
                        <span className="star">
                            <i className="fa fa-star red--star" aria-hidden="true"/>
                        </span>
                    )}
                </div>
                <div className="Apartament-info-text clearfix">
                    {Array.isArray(task?.services) === true && task?.services.map((service, index) => (
                        <span
                            key={`${task.id}-${index}`}>{service?.name}{index + 1 !== task.services.length ? ', ' : ''}</span>
                    ))}
                </div>

                <div className="Apartament-info-text-wrapper">
                    <div>
                        <p>Status: {task.doneTaskServicesCount}/{task.taskServicesCount}</p>
                        <p>Notatka: {task.clientDescription ?? '-'}</p>
                    </div>

                    {task.hasNewCommentToSee && (
                        <i className="fas fa-envelope envelope--notification"/>
                    )}
                </div>
            </div>
            {task.clientComment !== null && (
                <div className="modal fade" tabIndex="-1" role="dialog" id={`modal${task.id}`}
                     aria-labelledby="modalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modalLabel">Opis</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {task.clientComment}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

document.querySelectorAll('.group__apartment').forEach((item) => {
    const props = Object.assign({}, item.dataset);
    props.task = JSON.parse(props.task);
    ReactDOM.render(<GroupTask {...props} />, item);
})
