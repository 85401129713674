import React, {useEffect, useState} from "react";
import {Field} from "react-final-form";
import axios from "axios";
import {ClientServicesForm} from "./ClientServicesForm";
import {ServicesCreateForm} from "./ServicesCreateForm";

export const ServicesEdit = ({services, taskServices, canEditPrices, baseUrl, apartmentId, index, setServiceGroups, setServices}) => {
    const [showServices, setShowServices] = useState(true);
    const [showServicesToChoice, setShowServicesToChoice] = useState(false);

    const [selectedServices, setSelectedServices] = useState([]);
    const [toChoicesServices, setToChoicesServices] = useState([]);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        const selectedIds = taskServices.map(item => item.apartment_service_id);
        setSelectedServices(taskServices);
        setToChoicesServices(services.filter(item => !selectedIds.includes(item.id)));
    }, [services, taskServices]);

    useEffect(() => {
        if (!services.length && apartmentId) {
            axios.get(`${baseUrl}/api/apartments/services/${apartmentId}/client`,{
            }).then(({data}) => {
                setGroups([...data.groups]);
            })
        }
    }, [services])

    return (
        <>
            <div className="task-service-tle" onClick={() => {
                setShowServices(!showServices)
            }}>
                Usługi <i className={showServices ? "fa fa-angle-down" : "fa fa-angle-up"}
                          aria-hidden="true"/>
            </div>
            <div className={showServices ? "active--block" : "hidden--block"}>
                {selectedServices.map((selectedService, index) => (
                    <div key={`task_services-${selectedService.id}-${index}}`} >
                        <div className="content__wrapper">
                            <Field
                                name={`task_services[${index}][id]`}
                                type="checkbox"
                                initialValue={selectedService.id}
                                parse={v => (v ? selectedService.id : null)}
                                render={({input}) => {
                                    return (
                                        <div className="box1">
                                            <label className="check_box">{selectedService.name}
                                                <input
                                                    {...input}
                                                    checked={selectedService.selected}
                                                    onChange={(e) => {
                                                        input.onChange(e);
                                                        selectedService.selected = e.target.checked ? 1: 0;
                                                    }}
                                                />
                                                <span className="checkmark"/>
                                            </label>
                                        </div>
                                    );
                                }}
                            />
                            {canEditPrices && (
                                <Field
                                    name={`task_services[${index}][price]`}
                                    type="number"
                                    initialValue={selectedService.price}
                                    render={({input}) => {
                                        return (
                                            <div className="box1">
                                                <input {...input} className="input__price" min="0" step="0.01"
                                                       value={selectedService.price}
                                                       onChange={(e) => {
                                                           input.onChange(e);
                                                           selectedService.price = e.target.value;
                                                       }}
                                                />
                                            </div>
                                        );
                                    }}
                                />
                            )}
                            <Field
                                name={`task_services[${index}][status]`}
                                type="checkbox"
                                initialValue={selectedService.done}
                                render={({input}) => {
                                    return (
                                        <div className="box1">
                                            <label className="check_box">{selectedService?.done_at ?? '-'}
                                                <input
                                                    {...input}
                                                    checked={selectedService.done}
                                                    onChange={(e) => {
                                                        input.onChange(e);
                                                        selectedService.done = e.target.checked ? 1: 0;
                                                    }}
                                                />
                                                <span className="checkmark"/>
                                            </label>
                                        </div>
                                    );
                                }}
                            />
                        </div>

                        {selectedService.scopeOfWork !== null && (
                            <div className="content__wrapper">
                                <span style={{fontSize: 10}}>{selectedService.scopeOfWork}</span>
                            </div>
                        )}
                    </div>
                ))}

                <div>
                    Wszystkie <i className={showServicesToChoice ? "fa fa-angle-down" : "fa fa-angle-up"}
                                 onClick={() => {
                                     setShowServicesToChoice(!showServicesToChoice);
                                 }}
                                 aria-hidden="true"/>
                </div>
                {showServicesToChoice && (
                    <ServicesCreateForm index={index} services={toChoicesServices} canEditPrices={canEditPrices} setServices={setServices} />
                )}
                {showServicesToChoice && groups.length > 0 && toChoicesServices.length === 0  && (
                    <ClientServicesForm groups={groups} index={index} apartmentId={apartmentId} canEditPrices={canEditPrices} setServiceGroups={setServiceGroups}/>
                )}
            </div>
        </>
    )
}
