import React, {useEffect, useState} from 'react';
import {TaskForm} from "./Form";
import axios from "axios";
import {TaskComments} from "../Comments/TaskComments";
import {DeleteTask} from "./DeleteTask";

export const EditTask = ({taskId, statuses, groups, apartments, url, updateTaskBoard, isAdmin}) => {
    const [messageFile, setMessageFile] = useState(null);
    const [task, setTask] = useState(null);

    const closeModal = () => {
        document.querySelector('.notification.notification-pop-btn').click();
    }

    useEffect(() => {
        if (taskId !== null) {
            axios.get(`${url}/tasks/${taskId}`).then(({data}) => {
                setTask(data.task);
            })
        }
    }, [taskId]);

    const tempTask = {...task};

    if (task?.taskServices) {
        tempTask.apartment_services = tempTask?.taskServices.map(item => item.apartment_service_id);
    }

    return (
        <>
            {task !== null && (
                <>
                    <TaskForm
                        statuses={statuses}
                        groups={groups}
                        apartments={apartments}
                        url={url}
                        lat={task?.lat ?? 51.7592}
                        lng={task?.lng ?? 19.4560}
                        initialValues={tempTask}
                        apartmentInit={task.apartment_id}
                        groupInit={task.group_id}
                        statusInit={task.task_status_id}
                        taskServicesInit={task.taskServices}
                        isAdmin={isAdmin}
                        onSubmit={(values, form, resetForm, services = [], serviceGroups = []) => {
                            let copyValues = Object.assign({}, values);
                            copyValues.task_status_id = copyValues.task_status_id?.value ?? copyValues.task_status_id;
                            copyValues.apartment_id = copyValues.apartment_id?.value ?? copyValues.apartment_id;
                            copyValues.group_id = copyValues.group_id?.value ?? copyValues.group_id;
                            copyValues.task_services = copyValues.task_services?.filter(item => item?.id != null) ?? [];
                            copyValues.apartment_services = []
                            copyValues.groups= []

                            const selectedIds = copyValues?.taskServices?.map(item => item.apartment_service_id) ?? [];
                            if (services.length) {
                                if (isAdmin == 1) {
                                    copyValues.apartment_services = services.filter(item => !selectedIds.includes(item.id)).filter(item => item.obligatory);
                                } else {
                                    copyValues.apartment_services = services.filter(item => !selectedIds.includes(item.id)).filter(item => item.obligatory).map(item => ({
                                        id: item.id
                                    }));
                                }
                            }

                            if(serviceGroups.length){
                                copyValues.groups = serviceGroups.map(item => (
                                    {
                                        services: item.services.filter(item => item.obligatory)
                                    }
                                ));
                                if (isAdmin != 1) {
                                    copyValues.groups = copyValues.groups.map(item => (
                                        {
                                            services: item.services.map(item => ({id: item.id}))
                                        }
                                    ))
                                }
                            }

                            if(!copyValues.client_description){
                                copyValues.client_description = "";
                            }

                            axios.put(`${url}/tasks/${task.id}`, copyValues).then(() => {
                                closeModal();
                                updateTaskBoard();
                            }).catch((e) => {
                                if(e?.response?.status === 423){
                                    alert('Apartament do tego dnia został już dodany');
                                }
                                console.log(e);
                            })
                        }}

                        closeModal={() => {
                            closeModal();
                            updateTaskBoard();
                        }}
                    />
                    <TaskComments comments={task.comments} messageFile={messageFile} setMessageFile={setMessageFile}
                                  onSubmit={(values, form) => {
                                      const formData = new FormData();
                                      Object.keys(values).forEach((key) => {
                                          formData.append(key, values[key] ?? '')
                                      });
                                      if (messageFile) {
                                          formData.append('image', messageFile);
                                      }
                                      axios.post(`${url}/comments/${task.id}`, formData).then(({data}) => {
                                          form.reset();
                                          setMessageFile(null);
                                          setTask(null);
                                          closeModal();
                                      }).catch((e) => {
                                          console.log(e);
                                      })
                                  }}
                    />
                    <DeleteTask id={task.id} baseUrl={url} refresh={() => {
                        closeModal();
                        updateTaskBoard();
                    }}/>
                </>
            )}
        </>
    );
}
