import ReactDOM from "react-dom";
import React, {useEffect, useState} from "react";
import {startOfMonth} from "date-fns";
import {MonthlyCalendar} from "../components/Calendar/MonthlyCalendar";
import moment from "moment";

const CalendarPage = ({url}) => {

    let [currentMonth, setCurrentMonth] = useState(
        startOfMonth(new Date())
    );

    let [tasks, setTask] = useState([])

    useEffect(() => {
        let startDate = moment(currentMonth).locale('pl').startOf('week');
        let endDate = moment(currentMonth).locale('pl').add(1, 'M').startOf('month');

        axios.get(`${url}/calendar/getTasks`, {
            params: {
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD')
            }
        }).then(r => {
            setTask(r.data.tasks);
        })
    }, [currentMonth]);


    return (
        <MonthlyCalendar
            currentMonth={currentMonth}
            onCurrentMonthChange={date => setCurrentMonth(date)}
            days={["Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota", "Niedziela"]}
            tasks={tasks}
            onClick={(date) => {
                window.location.href = `${url}/dashboard?date=${date}`;
            }}
        />
    );
}

if (document.getElementById('calendarPage')) {
    const item = document.getElementById('calendarPage');
    const props = Object.assign({}, item.dataset);
    ReactDOM.render(<CalendarPage {...props} />, item);
}
