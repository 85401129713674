import ReactDOM from "react-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {ReportApartmentsForm} from "./ReportApartmentsForm";
import Loader from "react-loader-spinner";

const Report = ({url, report}) => {
    const [reportApartments, setReportApartments] = useState([]);
    const [apartments, setApartments] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${url}/api/reports/${report}`).then((response)=>{
            setReportApartments(response.data.reportApartments);
            setApartments(response.data.apartments);
        }).finally(()=>{
            setLoading(false);
        })
    }, [report]);

    return (
        <>
            {loading ? (
                <Loader
                    type="TailSpin"
                    color="#00BFFF"
                    height={100}
                    width={100}
                />
            ) : (
                <ReportApartmentsForm initReportApartments={reportApartments} apartments={apartments}/>
            )}
        </>
    )
}


if (document.getElementById('reportApartments')) {
    const item = document.getElementById('reportApartments');
    const props = Object.assign({}, item.dataset);
    ReactDOM.render(<Report {...props} />, item);
}
