import React from "react";

export const MonthlyDay = ({date, tasks, disabled, onClick}) => {
    return (
        <div className={`col cell flex fWrap aiStart jcBetween acAround  ${disabled ? 'disabled' : ''} `}
             onClick={() => {
                    onClick(date.format('YYYY-MM-DD'))
                    }
             }
        >
            <span className="number">{date.date()}</span>
            <ol className="eventsUL"/>
            <span className="bg">{date.date()}</span>
            <div className="task-wrapper">
                {tasks.map((task,index) => (
                    <p key={`p-${index}`} className="task-content">
                        {task.name}
                    </p>
                ))}
            </div>
        </div>
    );
}
