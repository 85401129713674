import ReactDOM from "react-dom";
import React from "react";
import ImageGallery from 'react-image-gallery';

const ImageGalleryView = ({images, height= 200, props}) => {
    return (
        <ImageGallery items={images.map(image => ({original:image.url, thumbnail:image.url, originalHeight:height}))} showFullscreenButton={false} showPlayButton={false} {...props} />
    );
}

document.querySelectorAll('.image__gallery').forEach((item)=>{
    const props = Object.assign({}, item.dataset);
    props.images = JSON.parse(props.images);
    ReactDOM.render(<ImageGalleryView {...props} />, item);
})
