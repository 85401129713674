import React, {useEffect, useState} from 'react';
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import axios from "axios";

export const TaskList = ({
                             url,
                             date,
                             change,
                             setTask,
                             setGroup,
                             updateTaskBoard,
                             notEndedTasks = false,
                             search = ""
                         }) => {
    const [groups, setGroups] = useState([]);
    const [state, setState] = useState([...groups.map((group) => group.tasks)]);
    const [reload, setReload] = useState(1);

    const changeGroup = (taskId, group, order) => {
        axios.put(`${url}/dashboard/tasks/${taskId}/changeGroup`, {groupId: group.id, order: order}).catch((e) => {
            console.log(e);
            window.alert('Nie udało się zmienić grupy.');
        })
    }

    const changeOrder = (ids) => {
        axios.put(`${url}/dashboard/tasks/changeOrder`, {ids: ids}).catch((e) => {
            console.log(e);
            window.alert('Nie udało się zmienić kolejności.');
        })
    }

    const deleteGroup = (groupId) => {
        axios.delete(`${url}/dashboard/tasks/${groupId}/group`)
            .catch((e) => {
                console.log(e);
                window.alert('Nie udało się usunąć grupy');
            })
            .finally(() => {
                updateTaskBoard();
            })
    }

    const openTaskWithGroup = (groupId) => {
        if (groupId !== null) {
            setTask(null);
            const modal = document.querySelector('.task-pop');
            if (modal) {
                if (!modal.classList.contains('open')) {
                    modal.classList.add('open');
                }
            }
            setGroup(groupId);
        }
    }

    const deleteTemporaryGroup = (groupId) => {
        if (window.confirm("Czy na pewno chcesz usunąć grupę ?")) {
            deleteGroup(groupId);
        }
    }

    useEffect(() => {
        setInterval(() => {
            setReload(reload + 1 + Math.random());
        }, 1000 * 60 * 5)
    }, []);

    useEffect(() => {
        const params = {};
        if (date !== null) {
            params.dateOfOrder = date;
        }
        params.notEndedTasks = notEndedTasks ? 1 : 0;
        if (search !== "") {
            params.search = search;
        }
        axios.get(`${url}/dashboard/tasks`, {params: params}).then((response) => {
            setGroups([...response.data.groups]);
            setState([...response.data.groups.map((group) => group.tasks)]);
        }).catch((e) => {
            console.log(e);
        })
    }, [date, notEndedTasks, change, reload, search]);

    /**
     * Moves an item from one list to another list.
     */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        changeGroup(removed.id, groups[droppableDestination.droppableId], droppableDestination.index);
        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        changeOrder(result.map((item) => item.id))
        return result;
    };

    function onDragEnd(result) {
        const {source, destination} = result;
        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(state[sInd], source.index, destination.index);
            const newState = [...state];
            newState[sInd] = items;
            setState(newState);
        } else {
            const result = move(state[sInd], state[dInd], source, destination);
            const newState = [...state];
            newState[sInd] = result[sInd];
            newState[dInd] = result[dInd];
            setState(newState);
        }
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "",
    });

    return (
        <div className="Taskboard-details">
            <div className="Taskboard-listings">
                <div className="taskboard-listings-column clearfix">
                    <DragDropContext onDragEnd={onDragEnd}>
                        {/*TODO one component !*/}
                        {state.map((el, ind) => (
                            <div className="listings-column" key={`${groups[ind]?.id}-list-${ind}`}>
                                <div className="taskboard-column-head taskboard-column-head-flex">
                                    <h6 className="pointer" onClick={() => {
                                        openTaskWithGroup(groups[ind]?.id)
                                    }}>
                                        {groups[ind]?.name}
                                    </h6>
                                    {groups[ind]?.id !== null && (
                                        <div className="head-right-icons head-right-icons-flex">
                                            <i className="fa fa-plus"
                                               aria-hidden="true"
                                               onClick={() => {
                                                   openTaskWithGroup(groups[ind].id)
                                               }}
                                            />
                                            {groups[ind]?.temporary === 1 && (
                                                <i className="fas fa-trash-alt"
                                                   aria-hidden="true"
                                                   onClick={() => {
                                                       deleteTemporaryGroup(groups[ind].id)
                                                   }}
                                                />
                                            )}
                                            <i className="fa fa-user" aria-hidden="true"
                                               onClick={() => {
                                                   if (groups[ind]?.id !== null) {
                                                       window.location.href = `${url}/groups/${groups[ind]?.id}/edit` + (date ? `?date=${date}` : '');
                                                   }
                                               }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <Droppable droppableId={`${ind}`}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                            className="listings-info"
                                            {...provided.droppableProps}
                                        >
                                            <div className="listing-hrading">{el.length} TASKI</div>
                                            <div className="listing-hrading-status">
                                                {el.reduce((accumulator, currentValue) => accumulator + currentValue.doneTaskServicesCount, 0)}
                                                /
                                                {el.reduce((accumulator, currentValue) => accumulator + currentValue.taskServicesCount, 0)}
                                            </div>
                                            {el.map((item, index) => (
                                                <Draggable
                                                    key={item.uniqueId}
                                                    draggableId={item.uniqueId}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                            className={`listings-info-column clearfix ${item?.status?.color} ${item?.free ? 'salmon--border' : ''}`}
                                                            onClick={() => {
                                                                item.hasNewCommentToSee = false;
                                                                setTask(item.id)
                                                            }}
                                                        >
                                                            <div className="listings-info-column-left">
                                                                <div className="info-number">{index + 1}</div>
                                                                <div className="info-list">
                                                                    <p>
                                                                        <i className="fas fa-dice-four"/> {item.apartment?.name} - <span
                                                                        className="info-list-small">{item?.address}</span>
                                                                    </p>
                                                                    <p>
                                                                        <i className="fas fa-user-alt"/> {item?.apartment?.client?.short_name ?? item?.apartment?.client?.name}
                                                                    </p>
                                                                    <p>
                                                                        <i className="far fa-calendar-alt"/> {item?.guest_arrival_at}
                                                                    </p>
                                                                    <div className="info-list-info">
                                                                        <p>
                                                                            <i className="fas fa-user-friends"/> {item?.people} / {item?.apartment?.size} m2
                                                                        </p>
                                                                        <p><i
                                                                            className="fas fa-list"/> {item.doneTaskServicesCount}/{item.taskServicesCount}
                                                                        </p>
                                                                    </div>

                                                                    <p>
                                                                        {item?.services?.map((item) => `${item.name.substring(0, 3)} `)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={`listings-info-column-right ${item?.free ? 'salmon--border' : ''}`}>
                                                                {(item.hasComments && !item.hasNewCommentToSee) && (
                                                                    <div className="drug">
                                                                        <i className="fas fa-envelope"/>
                                                                    </div>
                                                                )}
                                                                {item.hasNewCommentToSee && (
                                                                    <div className="drug">
                                                                        <i className="fas fa-envelope envelope--notification"
                                                                           style={{fontSize: '15px'}}/>
                                                                    </div>
                                                                )}
                                                                <div className="drug">
                                                                    <i className="fa fa-arrows-alt"
                                                                       aria-hidden="true"/>
                                                                </div>
                                                                {item.hasNote && (
                                                                    <div className="drug"
                                                                         style={{fontSize: '12px', fontWeight: 700}}>
                                                                        N
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        ))}
                    </DragDropContext>
                </div>
            </div>
        </div>
    );
}
