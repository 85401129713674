import React, {useEffect, useRef, useState, useCallback} from 'react';
import ReactDOM from "react-dom";
import {TaskList} from "../components/Tasks/List";
import moment from "moment";
import Doughnut from 'react-chartjs-2';
import axios from "axios";
import {CreateTask} from "../components/Tasks/Create";
import {EditTask} from "../components/Tasks/Edit";
import _debounce from 'lodash/debounce';


const DashboardPage = ({startdate, url, statuses, groups, apartments, isadmin}) => {
    const [date, setDate] = useState(startdate ? moment(startdate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));
    const [notEndedTasks, setNotEndedTasks] = useState(false);
    const [search, setSearch] = useState("");
    const [task, setTask] = useState(null);
    const [group, setGroup] = useState(null);
    const [change, setChange] = useState(1);
    const [resetForm, setResetForm] = useState(1);
    const [firstChar, setFirstChar] = useState({
        datasets: [
            {data: []}
        ],
        percent: null
    });
    const [secondChar, setSecondChar] = useState({
        datasets: [
            {data: []}
        ],
        percent: null
    });

    const taskModal = useRef(null);

    const updateTaskBoard = () => {
        setChange(change + 1);
    }

    useEffect(() => {
        const params = {};
        if (date !== null) {
            params.dateOfOrder = date;
        }
        axios.get(`${url}/dashboard/chars`, {params: params}).then((response) => {
            setFirstChar({
                datasets: [
                    {
                        data: response.data?.firstChar?.data,
                        backgroundColor: [
                            '#2ED47A',
                            '#FDEB41',
                        ],
                        borderWidth: 0,
                    }
                ],
                percent: response.data?.firstChar?.percent
            })
            setSecondChar({
                datasets: [
                    {
                        data: response.data?.secondChar?.data,
                        backgroundColor: [
                            '#e1e3e8',
                            '#109CF1',
                            '#2ED47A',
                            '#EE82EE',
                            '#00FF00',
                            '#F7685B',
                            '#FFA500',
                        ],
                        borderWidth: 0,
                    }
                ],
                percent: response.data?.secondChar?.percent
            })
        }).catch((e) => {
            console.log(e);
        })
    }, [date, change]);

    useEffect(() => {
        document.querySelector('.notification-pop-btn').addEventListener('click', () => {
            setTask(null);
            document.querySelector('.task-pop').classList.toggle('open');
        })

        const button = document.querySelector('.notification-pop-btn');
        const mutationObserver = new MutationObserver((mutationsList) => {
            mutationsList.forEach(mutation => {
                if (mutation.attributeName === 'class') {
                    if (mutation.target.classList.contains('open')) {
                        button.innerHTML = `<a href="#">Zamknij</a>`;
                        rerenderForm();
                    } else {
                        button.innerHTML = `<a href="#">Dodaj nowy task</a>`;
                    }
                }
            })
        })
        mutationObserver.observe(document.querySelector('.task-pop'), {attributes: true})
    }, []);


    useEffect(() => {
        if (task !== null) {
            document.querySelector('.task-pop').classList.add('open');
        }
    }, [task])

    const setTaskId = (taskId) => {
        if (task !== null) {
            closeTaskModal();
        } else {
            setTask(taskId);
        }
    }

    const rerenderForm = () => {
        taskModal.current.scrollTo(0, 0);
        setResetForm(resetForm + Math.random() + 1);
    }

    const closeTaskModal = () => {
        setTask(null);
        rerenderForm();
        document.querySelector('.task-pop').classList.remove('open');
    }


    const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

    function handleChange(event) {
        debounceFn(event.target.value);
    }

    function handleDebounceFn(inputValue) {
        setSearch(inputValue);
    }

    return (
        <>
            <div className="dashboard-wrapper">
                <div className="Taskboard-head clearfix">
                    <div className="Taskboard-title">
                        <h4>Taskboard</h4>
                        <div className="Taskboard-date">
                            <button onClick={() => {

                                window.location.href = `${url}/dashboard?date=${moment(date).add(-1, 'days').format('YYYY-MM-DD')}`;
                            }}
                            >
                                <i className="fas fa-chevron-left"/>
                            </button>
                            <input type="date" value={date} onChange={(e) => {

                                window.location.href = `${url}/dashboard?date=${e.target.value}`;
                            }}
                            />
                            <button onClick={() => {

                                window.location.href = `${url}/dashboard?date=${moment(date).add(1, 'days').format('YYYY-MM-DD')}`;
                            }}
                            >
                                <i className="fas fa-chevron-right"/>
                            </button>
                        </div>

                        <div style={{margin: "20px 0px 0px 30px"}}>
                            <div className="form-col form-col-long">
                                <input type="text" placeholder="Szukaj" onChange={handleChange}/>
                            </div>
                        </div>
                    </div>
                    <div className="Taskboard-head-right">
                        <div className="row">
                            <div className="col-md-4">
                                <input type="checkbox" onChange={(e) => {
                                    setNotEndedTasks((e.target.checked));
                                }}
                                />
                                Pokaż tylko niewykonane w pełni

                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="graph-col clearfix">
                                    <div className="chart-wrapper">
                                        <Doughnut data={firstChar} type={'doughnut'}/>
                                        <div className="tooltip">
                                            {firstChar.percent !== null && (
                                                <>{firstChar.percent}%</>
                                            )}
                                        </div>
                                    </div>
                                    <div className="chart-value chart-value2">
                                        <ul>
                                            <li><span className="value-color color-green"/> Sprawdzone Housedama
                                            </li>
                                            <li><span className="value-color color-yellow"/> Pozostałe</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="graph-col clearfix">
                                    <div className="chart-wrapper">
                                        <Doughnut data={secondChar} type={'doughnut'}/>
                                        <div className="tooltip">
                                            {secondChar.percent !== null && (
                                                <>{secondChar.percent}%</>
                                            )}
                                        </div>
                                    </div>
                                    <div className="chart-value">
                                        <ul>
                                            <li><span className="value-color color-white"/> Nowe</li>
                                            <li><span className="value-color color-blue"/> Aktywne</li>
                                            <li><span className="value-color color-green"/> Zakończone</li>
                                            <li><span className="value-color color-violet"/> Zakończone-niepełne</li>
                                            <li><span className="value-color color-darkgreen"/> Sprawdzone Housedama
                                            </li>
                                            <li><span className="value-color color-red"/> Niewykonane</li>
                                            <li><span className="value-color color-gray"/> Do zrobienia</li>
                                            <li><span className="value-color color-orange"/> Do poprawy</li>
                                            <li><span className="value-color color-salmon"/> Wolne</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TaskList url={url} date={date} notEndedTasks={notEndedTasks} change={change}
                          setTask={setTaskId}
                          updateTaskBoard={updateTaskBoard}
                          setGroup={(value) => {
                              setGroup(value);
                          }}
                          search={search}
                />
            </div>

            <div className="task-pop" ref={taskModal}>
                <div className="task-scroll">
                    <div className="task-details">
                        <i
                            className="fas fa-times close__modal"
                            onClick={() => {
                                closeTaskModal()
                            }}
                        />
                        {task !== null ? (
                            <EditTask
                                key={`${resetForm}-edit`}
                                taskId={task} url={url} statuses={statuses} groups={groups} apartments={apartments}
                                updateTaskBoard={updateTaskBoard}
                                isAdmin={isadmin}
                            />
                        ) : (
                            <CreateTask
                                key={resetForm}
                                url={url} lat={51.7592} lng={19.4560} statuses={statuses} groups={groups}
                                apartments={apartments}
                                date={date}
                                updateTaskBoard={updateTaskBoard}
                                groupId={group}
                                isAdmin={isadmin}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

if (document.getElementById('dashboardPage')) {
    const item = document.getElementById('dashboardPage');
    const props = Object.assign({}, item.dataset);
    props.statuses = JSON.parse(props.statuses);
    props.groups = JSON.parse(props.groups);
    props.apartments = JSON.parse(props.apartments);
    ReactDOM.render(<DashboardPage {...props} />, item);
}
