import {Field} from "react-final-form";
import React, {useEffect, useState} from "react";
import {ServicesCreateForm} from "./ServicesCreateForm";

export const ServicesCreate = ({services, index,setServices, canEditPrices}) => {
    const [showServices, setShowServices] = useState(true);
    const [newServices, setNewServices] = useState([]);

    useEffect(() => {
        setNewServices(services);
    }, [services]);

    return (
        <>
            <div className="task-service-tle" onClick={() => {
                setShowServices(!showServices)
            }}>
                Usługi <i className={showServices ? "fa fa-angle-down" : "fa fa-angle-up"}
                          aria-hidden="true"/>
            </div>
            <div className={showServices ? "active--block" : "hidden--block"}>
                <ServicesCreateForm index={index} services={newServices} setServices={setServices} canEditPrices={canEditPrices}/>
            </div>
        </>
    );
}
