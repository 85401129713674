import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select'
import axios from "axios";

export const UserForm = ({group, url, options, users = []}) => {
    const [selects, setSelects] = useState([]);

    useEffect(()=>{
        let values = users.map((user) => (
            {
                name:"users[]",
                placeholder:"Użytkownik",
                options: options,
                defaultValue: {
                    value: user?.id,
                    label: user?.name
                }
            }
        ));
        setSelects(values)
    },[options, users])


    const deleteUser = (id) => {
        axios.delete(`${url}/api/groups/${group}/user/${id}`).then(({data}) => {
            let values = data.users.map((user) => (
                {
                    name:"users[]",
                    placeholder:"Użytkownik",
                    options: options,
                    defaultValue: {
                        value: user?.id,
                        label: user?.email
                    }
                }
            ));
            setSelects(values)
        }).catch((e)=>{
            console.log(e);
        })
    }

    return (
        <>
            <div className="form-col">
                <div className="form-col-action">
                    <div className="add-minus" onClick={()=>{
                        setSelects(selects => {
                            selects.pop();
                            return [...selects]
                        });
                        }}>
                        <i className="fa fa-minus"/>
                    </div>

                    <div className="add-plus" onClick={()=>{
                        setSelects(selects => [...selects, {
                            name:"users[]",
                            placeholder:"Użytkownik",
                            options: options
                        }]);
                        }}>
                        <i className="flaticon-plus-symbol-button"/>
                    </div>
                </div>

                {selects.map((item,index) => (
                    <div className="form-col" key={index}>
                        <div className="form-col-flex">
                            <Select name={item.name} placeholder={item.placeholder} options={item.options} defaultValue={item.defaultValue}/>
                            {item.defaultValue?.value && (
                                <i className="fa fa-trash"
                                   onClick={()=>{
                                        deleteUser(item.defaultValue.value);
                                        }}
                                   aria-hidden="true"
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
};

if (document.getElementById('groupUserFormComponent')) {
    const item = document.getElementById('groupUserFormComponent');
    const props = Object.assign({}, item.dataset);
    props.options = JSON.parse(props.options);
    props.users = JSON.parse(props.users);
    ReactDOM.render(<UserForm {...props} />, item);
}
