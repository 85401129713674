import React from "react";
import {MonthlyDay} from "./MonthlyDay";

export const MonthlyRow = ({days = [], onClick}) => {
    return (
        <div className="row">
            {days.map((item, index) => (
                <MonthlyDay key={`day-${index}`}
                            date={item.day}
                            tasks={item.tasks}
                            disabled={item.disabled}
                            onClick={onClick}
                />
            ))}
        </div>
    );
}
