import React from 'react';
import axios from "axios";

export const DeleteTask = ({baseUrl, id, refresh}) => {

    const deleteAction = (taskId) => {
        if (confirm('Czy na pewno chcesz skasować zadanie ?')) {
            axios.delete(`${baseUrl}/tasks/${taskId}`).then(({data}) => {
                refresh();
            }).catch((e) => {
                console.log(e);
            })
        }
    }

    return (
        <div className="col-sm-12">
            <button type="button"
                    className="task-file-uploaded clearfix deleteTask"
                    onClick={() => deleteAction(id)}
            >
                Usuń zadanie
            </button>
        </div>
    );
}
