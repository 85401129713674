import React from "react";
import {Draggable} from "react-beautiful-dnd";

export const GroupOrderItem= ({item, index}) => {
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: '10px',
        margin: `0 0 8px 0`,

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "#363636",
        fontSize: '14px',
        fontWeight: 600,
        color:'#ffffff',
        // styles we need to apply on draggables
        ...draggableStyle
    });

    return (
        <Draggable key={item.id} draggableId={item.id} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}
                >
                    {item.content}
                </div>
            )}
        </Draggable>
    );
}
