import {Field} from "react-final-form";
import React from "react";

export const ClientServicesForm = ({groups, apartmentId, index, canEditPrices, setServiceGroups}) => {
    return (
        <>
            {groups.map((group, keyGroup) => (
                <div key={`${group.id}-${keyGroup}-${apartmentId}-${index}`}>
                    <p>{group.name}:</p>
                    {group.services.map((service, key) => (
                        <div key={`${service.id}-${group.id}-${key}-${keyGroup}`}>
                            <div className="content__wrapper" >
                                <Field
                                    key={`checkbox-${service.id}-${group.id}-${key}-${keyGroup}-${apartmentId}-${index}`}
                                    id={`${service.id}-${group.id}-${key}-${keyGroup}`}
                                    name={`groups[${keyGroup}]services[${key}][id]`}
                                    type="checkbox"
                                    parse={v => (v ? service.id : null)}
                                    render={({input}) => (
                                        <div className="content__wrapper">
                                            <div className="box1">
                                                <label className="check_box">{service.name}
                                                    <input {...input}
                                                           checked={service?.obligatory}
                                                           onChange={(e) => {
                                                               input.onChange(e);
                                                               const temp = [...groups];
                                                               temp[keyGroup].services[key].obligatory = e.target.checked ? 1: 0;
                                                               setServiceGroups(temp)
                                                           }}
                                                    />
                                                    <span className="checkmark"/>
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                />
                                {canEditPrices && (
                                    <Field
                                        key={`input-${service.id}-${group.id}-${key}-${keyGroup}-${apartmentId}-${index}`}
                                        name={`groups[${keyGroup}]services[${key}][price]`}
                                        type="number"
                                        render={({input}) => (
                                            <div className="box1">
                                                <input {...input} value={service.price}
                                                       className="input__price"
                                                       onChange={(e) => {
                                                           input.onChange(e);
                                                           const temp = [...groups];
                                                           temp[keyGroup].services[key].price = e.target.value;
                                                           setServiceGroups(temp)
                                                       }}
                                                       min="0"
                                                       step="0.01"
                                                />
                                            </div>
                                        )}
                                    />
                                )}
                            </div>
                            {service.scopeOfWork !== null && (
                                <div className="content__wrapper content__container">
                                    <span style={{fontSize: 10}}>{service.scopeOfWork}</span>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
}
