import ReactDOM from "react-dom";
import React, {useState} from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const SmartEditor = ({content, props}) => {
    const [value, setValue] = useState(content);

    return (
        <>
            <input type="hidden" name="content" value={value}/>
            <CKEditor
                editor={ClassicEditor}
                data={value}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue(data);
                    console.log({event, editor, data});
                }}
                {...props}
            />
        </>
    );
}

document.querySelectorAll('.SmartEditorComponent').forEach((item) => {
    const props = Object.assign({}, item.dataset);
    ReactDOM.render(<SmartEditor {...props} />, item);
})
