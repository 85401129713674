import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom";
import Select from "react-select";
import {initValuesToClientForm} from "../../data/values";
import {ActionButton} from "./ActionButton";
import {
    addGroup,
    addServiceToApartments,
    removeServiceFromApartments,
    updatePricesInApartments, updateServiceFromApartments
} from "../../actions/client/Actions";


export const ClientsForm = ({services, create, initGroups = [], client = [], baseurl=""}) => {
    const [groups, setGroups] = useState([]);
    const [update, setUpdate] = useState(1);


    const displayBorder = (index) => {
        return index !== 0 ? "form--border" : ""
    }

    useEffect(() => {
        if (initGroups.length) {
            setGroups(initGroups);
        } else {
            setGroups([{
                id: null,
                name: null,
                type: null,
                services: [
                    initValuesToClientForm(0, !!create),
                    initValuesToClientForm(1, !!create)
                ]
            }])
        }
    }, [services, initGroups])

    return (
        <>
            {groups.map((group, key) => (
                <div className="client-edit clearfix" key={`${group.id}-${key}`}>
                    <div className="client-top clearfix">
                        {!!!create && group.id !== null && (
                            <input type="hidden" name={`groups[${key}][id]`} value={group.id}/>
                        )}
                        <ActionButton
                            show={!!!create && group.id !== null}
                            closure={{
                                msg: "Czy chcesz dodać grupę tych usług do wszystkich apartamentów tego klienta ? Jeśli Apartament posiada już tą grupę usług nie zostanie ona dopisana. Jeśli apartament posiada tą grupę ale nie posiada wszystkich jej usług nie zostaną one dopisane",
                                action: addGroup,
                                params: [baseurl, client, group.id]
                            }}
                        />
                        <div className="form-col form-col-long">
                            <input type="text" required placeholder="Nazwa grupy" name={`groups[${key}][name]`}
                                   defaultValue={group.name}/>
                        </div>
                        <div className="form-col">
                            <select required name={`groups[${key}][type]`} defaultValue={group.type}
                                    onChange={(e) => {
                                        group.type = e.target.value;
                                        setUpdate(update + 1);
                                    }}
                            >
                                <option value="">Typ mnożnika(m2, inny)</option>
                                <option value="m2">m2</option>
                                <option value="inny">inny</option>
                                <option value="zakres">zakres</option>
                            </select>
                        </div>
                        <div className="form-col">
                            {key === 0 && (
                                <div className="add-plus" onClick={() => {
                                    setGroups(groups => [...groups, {
                                        id: null,
                                        name: null,
                                        type: null,
                                        services: [initValuesToClientForm(key + 2, !!create)]
                                    }])
                                }}>
                                    <i className="flaticon-plus-symbol-button"/>
                                </div>
                            )}

                            {key !== 0 && (
                                <div className="add-minus" onClick={() => {
                                    let newArr = [...groups];
                                    newArr.splice(key, 1);
                                    setGroups([...newArr]);
                                }}>
                                    <i className="fa fa-minus"/>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="client-edit-bottom">
                        {group.services.map((service, serviceKey) => (
                            <div key={`${service?.id}-${group?.id}`}>
                                <div>
                                    {!!!create && service.id !== null && (
                                        <input type="hidden" name={`groups[${key}][services][${serviceKey}][id]`}
                                               value={service.id}/>
                                    )}

                                    <div className={`form-col form-col-mid ${displayBorder(serviceKey)}`}>
                                        <ActionButton
                                            show={!!!create && service?.id !== null}
                                            closure={{
                                                msg: "Czy chcesz dodać tą usługę do wszystkich apartmentów tego klienta ? Zostanie dodana tylko ta usługa. Jeśli apartament nie posiada tej grupy usług zostanie ona przypisana do apartamentu",
                                                action: addServiceToApartments,
                                                params: [baseurl, client, service?.id]
                                            }}
                                            wrapperClasses="add--blue"
                                        />

                                        {group.type === 'inny' && (
                                            <ActionButton
                                                show={!!!create && service?.id !== null}
                                                closure={{
                                                    msg: "Czy chcesz zmienić cenę tej usługi we wszystkich apartamentach tego klienta ?",
                                                    action: updateServiceFromApartments,
                                                    params: [baseurl, client, service?.id]
                                                }}
                                                wrapperClasses="add--green"
                                            />
                                        )}
                                        <Select name={`groups[${key}][services][${serviceKey}][service_id]`}
                                                placeholder="Usługi" options={services}
                                                defaultValue={service.serviceValue}/>
                                    </div>
                                    <div className={`form-col ${displayBorder(serviceKey)}`}>
                                        {group.type === 'inny' && (
                                            <input required type="number" min="0" step="0.01"
                                                   name={`groups[${key}][services][${serviceKey}][price]`}
                                                   placeholder="Cena jednostkowa" defaultValue={service.price}/>
                                        )}

                                    </div>
                                    <div className={`form-col form-col-mid ${displayBorder(serviceKey)}`}>
                                        <input type="text"
                                               name={`groups[${key}][services][${serviceKey}][scopeOfWork]`}
                                               placeholder="Zakres prac" defaultValue={service.scopeOfWork}/>
                                    </div>

                                    {serviceKey === 0 && (
                                        <div className="form-col" style={{display:'flex'}}>
                                            <div className="add-plus add--blue" onClick={() => {
                                                group.services = [...group.services, initValuesToClientForm((serviceKey + 1) * 1000, !!create)]
                                                setUpdate(update + 1);
                                            }}>
                                                <i className="flaticon-plus-symbol-button"/>
                                            </div>

                                            <ActionButton
                                                show={!!!create && service?.id !== null}
                                                closure={{
                                                    msg: "Czy chcesz dodać usunąć tą usługę z apartamentów ?",
                                                    action: removeServiceFromApartments,
                                                    params: [baseurl, client, service?.id, ()=>{
                                                        let temporary = group;
                                                        temporary.services.splice(serviceKey,1);
                                                        group.services= [...temporary.services];
                                                        setUpdate(update + 1);
                                                    }]
                                                }}
                                                wrapperClasses="add--red"
                                            />
                                        </div>
                                    )}

                                    {serviceKey !== 0 && (
                                        <div className={`form-col ${displayBorder(serviceKey)}`} style={{display:'flex'}}>
                                            <div className="add-minus add--blue" onClick={() => {
                                                group.services.splice(serviceKey, 1);
                                                group.services = [...group.services];
                                                setUpdate(update + 1);
                                            }}>
                                                <i className="fa fa-minus"/>
                                            </div>

                                            <ActionButton
                                                show={!!!create && service?.id !== null}
                                                closure={{
                                                    msg: "Czy chcesz dodać usunąć tą usługę z apartamentów ?",
                                                    action: removeServiceFromApartments,
                                                    params: [baseurl, client, service?.id, ()=>{
                                                        let temporary = group;
                                                        temporary.services.splice(serviceKey,1);
                                                        group.services= [...temporary.services];
                                                        setUpdate(update + 1);
                                                    }]
                                                }}
                                                wrapperClasses="add--red"
                                            />
                                        </div>
                                    )}
                                </div>

                                {group.type !== 'inny' && (
                                    service.prices?.map((price, priceKey) => (
                                        <div key={`${serviceKey}-${priceKey}-${service?.id}-${group?.id}-${price?.id}`}>
                                            {!price.newField && price.id !== null && (
                                                <input type="hidden"
                                                       name={`groups[${key}][services][${serviceKey}][prices][${priceKey}][id]`}
                                                       value={price.id}/>
                                            )}
                                            <div className="form-col form-col-small services__action__container">
                                                <ActionButton
                                                    show={!!!create && price?.id !== null && !price.newField }
                                                    closure={{
                                                        msg: "Czy chcesz doknać aktualizacji cen dla tego zakresu dla wszystkich apartamentów tego klienta dla tej usługi ? Jeśli nie ma przypisanej usługi do apartamentu nie zostanie ona utworzona, funkcja dokona tylko aktualizacji ceny.",
                                                        action: updatePricesInApartments,
                                                        params: [baseurl, client, price?.id]
                                                    }}
                                                    wrapperClasses="add--green"
                                                />
                                                <input required type="number" min="0" step="0.01"
                                                       name={`groups[${key}][services][${serviceKey}][prices][${priceKey}][from]`}
                                                       placeholder="Od (m2)" defaultValue={price.from}/>
                                            </div>
                                            <div className="form-col form-col-small">
                                                <input required type="number" min="0" step="0.01"
                                                       name={`groups[${key}][services][${serviceKey}][prices][${priceKey}][to]`}
                                                       placeholder="Do (m2)" defaultValue={price.to}/>
                                            </div>
                                            <div className="form-col form-col-small">
                                                <input required type="number" min="0" step="0.01"
                                                       name={`groups[${key}][services][${serviceKey}][prices][${priceKey}][price]`}
                                                       placeholder="Cena" defaultValue={price.render_price}/>
                                            </div>

                                            {priceKey === 0 && (
                                                <div className="form-col">
                                                    <div className="add-plus" onClick={() => {
                                                        group.services[serviceKey].prices = [...group.services[serviceKey].prices, {
                                                            id: priceKey,
                                                            from: null,
                                                            to: null,
                                                            render_price: null,
                                                            newField: true
                                                        }]
                                                        setUpdate(update + 1);
                                                    }}>
                                                        <i className="flaticon-plus-symbol-button"/>
                                                    </div>
                                                </div>
                                            )}

                                            {priceKey !== 0 && (
                                                <div className="form-col">
                                                    <div className="add-minus" onClick={() => {
                                                        group.services[serviceKey].prices.splice(priceKey, 1);
                                                        setUpdate(update + 1);
                                                    }}>
                                                        <i className="fa fa-minus"/>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </>
    );
};

if (document.getElementById('clientsFormComponent')) {
    const item = document.getElementById('clientsFormComponent');
    const props = Object.assign({}, item.dataset);
    props.services = JSON.parse(props.services);
    props.initGroups = JSON.parse(props.initgroups);
    ReactDOM.render(<ClientsForm {...props} />, item);
}
