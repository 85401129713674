import ReactDOM from "react-dom";
import React, {useEffect, useState} from "react";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {GroupOrderItem} from "./GroupOrderItem";
import axios from "axios";

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: 8,
    width: 250
});

const GroupOrderBoard = ({init}) => {
    const [items, setItems] = useState([]);
    const [date, setDate] = useState(init);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const newOrderItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems([...newOrderItems]);
    }

    const save = () => {
        axios.put(`/dashboard/tasks/reorder`, {
            groups: items.map(item => item.groupId),
            date: date
        })
            .catch((e) => {
                console.log(e);
                window.alert('Coś poszło nie tak');
            })
            .then(() => {
                location.reload();
            })
    }

    useEffect(() => {
        axios.get('/dashboard/tasks/reorder', {
            params: {
                date: date
            }
        }).then((response) => {
            setItems(response.data.groups.map(item => ({id: `item-${item.id}`, groupId: item.id, content: item.name})))
        })
    }, [date]);

    return (
        <>
            <input type="date" className="mb-2" value={date} onChange={(e) => {
                setDate(e.target.value)
            }}/>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {items.map((item, index) => (
                                <GroupOrderItem item={item} index={index}/>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <button className="btn" style={{marginTop: '10px'}} onClick={() => {
                save();
            }}>
                Zapisz
            </button>
        </>
    );
}

if (document.getElementById('groupOrderComponent')) {
    const item = document.getElementById('groupOrderComponent');
    const props = Object.assign({}, item.dataset);
    ReactDOM.render(<GroupOrderBoard {...props} />, item);
}
