import React, {useState} from "react";
import moment from "moment";

export const MonthlyNav = ({currentMonth, onCurrentMonthChange}) => {
    return (
        <div className="header row flex-middle ">
            <div className="col-start">
                <div className="icon" onClick={() => {
                    onCurrentMonthChange(moment(currentMonth).add(-1, 'month').toDate())
                    }}>
                    <i className="fas fa-angle-left"/>
                </div>
            </div>
            <div className="col col-center">
                <span>
                    {moment(currentMonth).locale('pl').format('MMM YYYY')}
                </span>
            </div>
            <div className="col-end">
                <div className="icon" onClick={() => {
                    onCurrentMonthChange(moment(currentMonth).add(1, 'month').toDate())
                    }}>
                    <i className="fas fa-angle-right"/>
                </div>
            </div>
        </div>
    );
}
