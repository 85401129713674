import React, {useRef, useState, useMemo, useEffect} from 'react';
import ReactDOM from 'react-dom';
import L from 'leaflet';
import {MapContainer, TileLayer, Marker, useMap} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

import { OpenStreetMapProvider } from 'leaflet-geosearch';


import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const DraggableMarker = ({center, setLatLngInputs}) => {
    const [position, setPosition] = useState(center)
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
        () => ({
        dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng())
                    const markerParams = marker.getLatLng();
                    setLatLngInputs(markerParams.lat, markerParams.lng);
                }
            },
        }),
        [],
    )

    useEffect(() => {
        setPosition(center);
    },[center])

    return (
        <Marker
            draggable={true}
            eventHandlers={eventHandlers}
            position={position}
            ref={markerRef}>
        </Marker>
    )
}

export const Map = ({lat, lng, latid, lngid}) => {
    const [center, setCenter] = useState({
        lat,
        lng
    });
    const [address, setAddress] = useState(null);
    const [city, setCity] = useState(null);
    const provider = new OpenStreetMapProvider();

    useEffect(()=>{
        document.querySelector('input[name="address"]').addEventListener('input', (e) => {
            setAddress(e.target.value);
        });
        document.querySelector('input[name="city"]').addEventListener('input', (e) => {
            setCity(e.target.value);
        });
    },[])

    useEffect(()=>{
        if (address !== null && city !== null) {
            provider.search({ query: `${city} ${address}`  }).then(function (result) {
                if (result?.length) {
                    const city = result[0];
                    setCenter({
                        lat: city.y,
                        lng: city.x,
                    });
                    setLatLngInputs(city.y, city.x);
                }
            });
        }
    },[address, city])



    const setLatLngInputs = (newLat, newLng) => {
        document.querySelector(latid).value = newLat;
        document.querySelector(lngid).value = newLng;
    }

    const ChangeView = ({ center }) => {
        const map = useMap();
        map.setView(center);
        return null;
    }

    return <>
        <MapContainer center={center} zoom={20} scrollWheelZoom={false} style={{ height: "100%" }}>
            <ChangeView center={center} />
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <DraggableMarker center={center} setLatLngInputs={setLatLngInputs} />
        </MapContainer>
    </>;
}

if (document.getElementById('apartmentsMap')) {
    const item = document.getElementById('apartmentsMap');
    const props = Object.assign({}, item.dataset);
    ReactDOM.render(<Map {...props} />, item);
}
