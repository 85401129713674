import React, {useEffect, useState} from "react";
import axios from "axios";
import {ClientServicesForm} from "./ClientServicesForm";

export const ClientServicesCreate = ({baseUrl, apartmentId, index, canEditPrices, setServiceGroups}) => {
    const [showServices, setShowServices] = useState(true);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        if (apartmentId) {
            axios.get(`${baseUrl}/api/apartments/services/${apartmentId}/client`).then(({data}) => {
                setGroups([...data.groups]);
                setServiceGroups([...data.groups]);
            })
        }
    }, [apartmentId]);

    return (
        <>
            <div className="task-service-tle" key={`${index}-${apartmentId}`} onClick={() => {
                setShowServices(!showServices)
            }}>
                Usługi <i className={showServices ? "fa fa-angle-down" : "fa fa-angle-up"}
                          aria-hidden="true"/>
            </div>
            <div className={showServices ? "active--block" : "hidden--block"}>
                <div>
                    Brak przypisanych usług do danego apartamentu.
                </div>
                <ClientServicesForm groups={groups} index={index} apartmentId={apartmentId} canEditPrices={canEditPrices} setServiceGroups={setServiceGroups}/>
            </div>
        </>
    );
}
